// import James from "../images/avatars/james-miller.jpg"
// import Jet from "../images/about/about-jet.svg"
// import Layout from "../components/layout"
// import React from "react"
// import Seo from "../components/seo"
// import Tanner from "../images/avatars/tanner-mares.png"
// import Will from "../images/avatars/will-dossantos.png"

const About = () => null
// const About = () => (
//   <Layout>
//     <Seo title="About" />
//     <section style={{ marginTop: "4em" }}>
//       <div className="container">
//         <div className="section--header">
//           <h2>Our Story</h2>
//           <h4>We have been standing by for a long time in the making</h4>
//         </div>
//       </div>
//     </section>
//     <section className="bio">
//       <div className="container">
//         <div className="grid grid-2">
//           <div className="content">
//             <p>
//               Treating people right is fundamental to how we do business. We
//               treat our customers as we’d want to be treated, we treat each
//               other like family, and we treat ourselves to a good day’s work and
//               a good night’s sleep.
//             </p>
//             <br />
//             <p>
//               Our goals have always been the same: Have fun, do exceptional
//               work, build the best product in the business, experiment, pay
//               attention to the details, treat people right, tell the truth, have
//               a positive impact on the world around us, give back, and keep
//               learning.
//             </p>
//             <br />
//             <p>
//               We’re also big believers in business 101. We don’t spend more than
//               we earn, we don’t waste money on things that don’t matter, we
//               don’t give away everything for free and hope we’ll figure it out
//               before we run out of cash. We’re in business to stay in business,
//             </p>
//           </div>
//           <img src={Jet} alt="About jet" />
//         </div>
//       </div>
//     </section>
//     <section
//       className="section--color section--about"
//       style={{ padding: "4em 0" }}
//     >
//       <div className="section--header">
//         <h2>Our Team</h2>
//       </div>
//       <div className="container">
//         <div
//           className="card card--about"
//           style={{ maxWidth: "600px", margin: "2em auto 0 auto" }}
//         >
//           <ul>
//             <li>
//               <strong>Leadership</strong>
//             </li>
//             <li>
//               <div className="avatar-container">
//                 <img src={James} alt="James Miller" />
//               </div>
//               <p>James Miller</p>
//             </li>
//             <li>
//               <div className="avatar-container">
//                 <img src={Tanner} alt="Tanner Mares" />
//               </div>
//               <p>Tanner Mares</p>
//             </li>
//             <hr />
//             <li>
//               <strong>Design</strong>
//             </li>
//             <li>
//               <div className="avatar-container">
//                 <img src={Will} alt="Will dos Santos" />
//               </div>
//               <p>Will dos Santos</p>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </section>
//     <section>
//       <div className="container">
//         <div className="section--header" style={{ marginTop: "4em" }}>
//           <h2>Get started for free</h2>
//           <p>
//             Start scheduling and alerting in minutes. Try it now, free for 14
//             days. Signup now, cancel anytime, no credit card required.
//           </p>
//           <div
//             className="btn btn--primary"
//             style={{ marginTop: "2em", marginBottom: "4em" }}
//           >
//             Try it free for 14 days
//           </div>
//         </div>
//       </div>
//     </section>
//   </Layout>
// )

export default About
